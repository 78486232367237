<template>
    <div class="col-lg-6 mt-3">
      <h2>Position</h2>
    </div>
  <div class="container">
    <div class="card">
      <div class="mt-4 ms-3 row">
          <div class="col">
            <h4 class="mb-3">ข้อมูลตำแหน่ง</h4>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"><strong>ไอดี:</strong>{{detail.id}}</li>
              <li class="list-group-item"><strong>ชื่อ:</strong>{{detail.name_th}}</li>
              <li class="list-group-item"><strong>Name:</strong> {{detail.name_en}}</li>
              <li class="list-group-item"><strong>รายละเอียด:</strong> ทำไรก็ได้โตแล้ว</li>
            </ul>
          </div>
        </div>
      </div>
    </div> 
 
</template>
<script>
import MaterialBotton from "/src/components/MaterialButton.vue";
import axios from 'axios';

export default {
  components: {  },
  data() {
    return {MaterialBotton,
        detail:[]
      
      
    };
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail(){
      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/position/"+this.$route.query.id,config)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.detail=result.data.data
        console.log(this.detail);
      }
      }
    },
};
</script>
