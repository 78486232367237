<template>
<h2 >Member Profile</h2>
<div class="container">
  <div class="card shadow-lg p-4">
    <div class="row g-4 align-items-center">
      <div class="col-md-4 text-center">
        <div class="text-center mb-4">
          <material-avatar
            :img="marie"
            class="mb-4"
            alt="Avatar"
            size="xxl"
            border-radius="md"
          />
        </div>
        
      </div>
      <div class="col-md-8">
        <h4 class="mb-3">ประวัติส่วนตัว</h4>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>First Name:</strong> {{detail.first_name_en}}</li>
          <li class="list-group-item"><strong>Last Name:</strong> {{detail.last_name_en}}</li>
          <li class="list-group-item"><strong>Nickname:</strong> {{detail.nickname_en}}</li>
          <li class="list-group-item"><strong>Identity card no:</strong> {{detail.c_id}}</li>
          <li class="list-group-item"><strong>Start work:</strong> {{detail.start_work_date}}</li>
          <li class="list-group-item"><strong>Role:</strong> {{detail.role_id?.name_en||""}}</li>
          <li class="list-group-item"><strong>Position in company:</strong> {{detail.position_id?.name_en||""}}</li>
          <li class="list-group-item"><strong>Level:</strong> [Add Level]</li>
          <li class="list-group-item"><strong>Phone Number:</strong> {{detail.tel}}</li>
          <li class="list-group-item"><strong>ID Line:</strong> {{detail.line_id}}</li>
          <li class="list-group-item"><strong>Address:</strong> {{detail.contact}}</li>
          <li class="list-group-item"><strong>Email:</strong> {{detail.email}}</li>
        </ul>
        <div class="col-lg-6 d-flex justify-content-end align-items-center">
          <a class="  btn bg-gradient-success ms-lg-auto" href="/adminpage/profilefinance">Profile Finance</a>
        <a class="mt-2 btn bg-gradient-success ms-lg-auto " href="/adminpage/editmember">
          <i class="material-icons text-white position-relative text-md pe-2">edit</i>Edit</a>
      </div>
      </div>
    </div>
  </div>
</div>

    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">ประวัติการทำงาน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>ตำแหน่งงาน</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">สำเร็จ</td>
                  <td class="text-sm font-weight-normal">more</td>
            

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
  </template>
  
  <script>

  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import sophie from "@/assets/img/kal-visuals-square.jpg";
  import { DataTable } from "simple-datatables";
  import marie from "@/assets/img/marie.jpg";
  import MaterialAvatar from "/src/components/MaterialAvatar.vue";
  import axios from 'axios';
  import { nextTick } from "vue";
  export default {
    name: "ProfileOverview",
    components: {MaterialAvatar,

    },
    data() {
      return {
        showMenu: false,
        sophie,
        marie,
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
        detail:{},
  
      };
    },
  
    mounted() {
  
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
    this.fetchDetail()
    },
    methods: {
    async fetchDetail(){
      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/staff/"+this.$route.query.id,config)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.detail=result.data.data
        await nextTick()
        console.log(this.detail);
      }
      }
    },
  };
  </script> 
  <style scoped>
  .img {
   max-width: 100%;
   height: auto;
  }

  </style>
  