<template>
    <h2>Report</h2>
    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการายงาน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Fullname</th>
                  <th>Emial</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr v-for="report in reports" :key="report.id">
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">{{report.fullname}}</td>
                  <td class="text-sm font-weight-normal">{{report.email}}</td>
                  <td class="text-sm font-weight-normal">{{report.title}}</td>
                  <td class="text-sm font-weight-normal">{{report.description}}</td>
                  <td class="text-sm font-weight-normal">
                  <a :href="`/adminpage/reportdetail?id=${report.id}`">more</a>
                </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        
  </template>
  
  <script>

  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { DataTable } from "simple-datatables";
  import marie from "/src/assets/img/marie.jpg";
  import MaterialAvatar from "../components/MaterialAvatar.vue";
  import axios from 'axios';
  import { nextTick } from "vue";
  export default {
    components: { MaterialAvatar
    
   
      
    },
    data() {
      return {marie,
        showMenu: false,
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
        reports:[]

  
      };
    },
  
    mounted() {
  
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    this.reportList()
    },
    methods:{
    async reportList(){
      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    try {
      const response = await axios.get("https://uat-api.susenginetech.com/api/v1/report/list",config)
      
      if(response.data.isError){
        alert(response.data.errorMessage)
      }else{
        this.reports=response.data.data
        await nextTick()
         // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });

      }
      console.log("this.report",this.reports);
    } catch (error) {
      console.log(error);
    }
  }
}
  };
  </script> 
  <style scoped>
  .img {
   max-width: 100%;
   height: auto;
  }

  </style>
  