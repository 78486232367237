<template>
  <navbar btn-background="bg-gradient-success" />
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url(&quot;https://images.unsplash.com/photo-1520769945061-0a448c463865?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80;);
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-info border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Reset Password
                </h4>
              <div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3" @submit.prevent="handleSubmit">
                <div class="mb-3">
                  <material-input
                  v-model="formData.email" required
                  placeholder="john@email.com"
                  class="email-input" 
                  
                  />
                </div>
                <p><a href="">Sign In</a></p>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    full-width
                    >Reset</material-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}, made with
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a
                href="#"
                class="font-weight-bold text-white"
                target="_blank"
                >Suset Co.,Ltd</a
              >
              for a better web.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="/projectpage"
                  class="nav-link text-white"
                  target="_blank"
                  >Project</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/projectpage"
                  class="nav-link text-white"
                  target="_blank"
                  >Co-Worker</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/member"
                  class="nav-link text-white"
                  target="_blank"
                  >Member</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="/Report"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Report</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      formData: {
        email: "",
      },
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleSubmit() {
      try {
        console.log("Form submitted:", this.formData);
        const payload = {
          email: this.formData.email,
        };
        const result = await axios.post("https://uat-api.susenginetech.com/api/v1/auth/forgot-password",payload);

        if (result.data.isError) {
          alert(result.data.errorMessage);
        } else {
          console.log(result.data.data);
          alert(result.data.data);
          this.formData.email = ""; // Reset the email field
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  },
};
</script>
