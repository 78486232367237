<template>
    <div class="col-lg-6 mt-3">
      <h2>Report</h2>
    </div>
  <div class="container">
    <div class="card">
      <div class="mt-4 ms-3 row">
          <div class="col">
            <h4 class="mb-3">ข้อมูลรายงาน</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>Name:</strong>{{detail.fullname}}</li>
              <li class="list-group-item"><strong>Email:</strong> {{detail.email}}</li>
              <li class="list-group-item"><strong>Title:</strong> {{detail.title}}</li>
              <li class="list-group-item"><strong>Description:</strong> {{detail.description}}</li>
              
            </ul>
          </div>
        </div>
      </div>
    </div> 
 
</template>
<script>
import MaterialBotton from "/src/components/MaterialButton.vue";
import axios from 'axios';
import { nextTick } from "vue";
export default {
  components: {  },
  data() {
    return {MaterialBotton,
        detail:
        {
  create_date: "",
  create_by: null,
  update_date: "",
  update_by: null,
  id: 6,
  fullname: "",
  email: "",
  title: "",
  description: "",
  status: true,
  staff_owner: null
}
      
      
    };
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail(){
      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/report/"+this.$route.query.id,config)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.detail=result.data.data
        await nextTick()
        console.log(this.detail);
      }
      console.log("this.detail",this.detail);
      }
    },
};
</script>
